:root {
  --blue: #052C43;
  --background: #F4F4F4;
  --pink:#FD4698;
  --green:#24D79E;
  --yellow:#FCC72E;
  --grey:#DBDBDB;

  --bigFont: 99px;
  --mediumFont:48px;
}

body{
  line-height: normal;
  background-color:var(--background);
  font-size: 18px;
}

h3 {
    color: var(--pink);
    font-weight: bold;
    font-size: var(--mediumFont);
    line-height: var(--mediumFont);
    margin-top: 50px;
}

h2{
  margin-bottom:0px;
  color:var(--green);
  font-size: var(--bigFont);
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  margin-top:50px;
  line-height: var(--bigFont);
}

.container{
  width:90%;
  max-width:1920px;
  margin:0 auto;
}

.ant-tag{
   font-weight: bold;
   margin-bottom:8px;
   font-size: 14px;
   padding:7px 8px 4px 8px;
   text-transform: uppercase;
   text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
}

.ant-image{
  display:block;
}

#hero{
  background-color: var(--blue);
  background : linear-gradient(180deg, rgba(5, 73, 90, 1) 0%, rgba(5, 44, 67, 1) 100%);

  padding-top:24px;
  height:calc(100vh - 50px);
}


#hero .navigation{
  font-size:14px;
  color:#DBDBDB;
}

#hero-center{
  display: flex;
  justify-content: center;
  align-items: center;
  height:calc(100% - 50px);
}

#hero #logo{
  background-image: url('img/logo.svg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  width:100%;
  height:150px;
  margin-bottom:24px;
}
#hero #name{
  opacity: 0;
}

@keyframes fadeIn {
  0%{
    opacity: 0.0;
  }
  100%{
    opacity: 1.0;
  }
}

#hero-text{
  text-align: center;
  opacity:0;
  animation: fadeIn 0.5s ease-in-out 0.5s forwards;
}



#hero-text h2{
  font-weight:600;
  font-style: normal;
  margin:0;
  font-size: 36px;
  text-transform: none;
  text-align: center;
  line-height: normal;

}
#hero-text h3{
  font-weight: 400;
  font-style: italic;
  margin:0;
  font-size: 20px;
  color:#DBDBDB;
  line-height: normal;
}

@keyframes scribble {
  0% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }
  50% {
    clip-path: polygon(0 0, 85% 0, 19% 100%, 0 100%);
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

#hero-left-text{
  display: inline-block;
}

#hero-text #strike{
  font-style: italic;
  font-weight: 300;
  position: relative;
  overflow: hidden;
}

#hero-text #strike::before{
  display: block;
  top:0;
  left:0;
  right:0;
  bottom:0;
  content:' ';
  position: absolute;
  background-image: url('img/scribble.svg');
  background-position: center;
  background-size: contain;
  background-repeat:no-repeat;
  animation: 0.6s ease-in 1.25s 1 scribble forwards;
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
}

@keyframes slam
{
  0%
  {
    transform: scale(5, 5);
    opacity: 0;
    transform:translateX(200%);
    width:0px;
  }

  30%
  {
    opacity: 0;
  }

  60%
  {
    width:0px;
  }

  100%{
    transform: scale(1.2, 1.2);
    opacity: 1;
    transform:translateX(0%);
    width:130px;
  }
}


#hero-text #visionary{
  font-family: skippy-sharp, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color:#fff;
  margin-left:8px;
  opacity: 0;
  display: inline-block;
  width:0px;
  overflow: visible;
  vertical-align: text-top;

  animation: slam 0.5s ease-in-out 1.85s forwards;
}



@keyframes fadeInAndSlide {
  0%{
    opacity: 0.0;
    transform:translateY(20%);
  }
  100%{
    opacity: 1.0;
    transform:translateY(0%);
  }
}


#portfolio{
  margin-top:50px;
  background-color: var(--green);
  padding-top:50px;
  padding-bottom:50px;
  /*z-index: 2;
  opacity: 0;
  animation: fadeInAndSlide 0.5s ease-in-out 1.9s forwards;*/
}

#portfolio h2{
  color:var(--blue);
  text-align: left;
  margin-bottom: 50px;
  margin-top:0;
}

#portfolio .ant-card{
  height:100%;
}

#portfolio .ant-card-hoverable{
  box-shadow: 0 1px 2px -2px rgb(5 44 67 / 16%);
}
#portfolio .ant-card-hoverable:hover{
  box-shadow: 0 1px 2px -2px rgb(5 44 67 / 16%), 0 3px 6px 0 rgb(5 44 67 / 12%), 0 5px 12px 4px rgb(5 44 67 / 9%);
}


.portfolio-card .company{
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
}

.portfolio-card .date{
  color:#969696;
  margin-left:6px;
}

.portfolio-card .title{
  color:var(--pink);
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 0;
  margin-top:0;
}

.portfolio-card .title .anticon{
  margin-right:8px;
}

.portfolio-card .ant-tag{
  margin-right:0;
  position: relative;
  float: right;
  margin-left:8px;
}

#about{
  margin-top:50px;
  position: relative;
  border:11px solid var(--green);
  min-height:calc(100vh - 100px);

  display: flex;
  justify-content: left;
  align-items: center;
}

#about p:last-of-type{
  margin-bottom: 0;
}

#big-about-text{
  font-weight : bold;
  font-size : var(--bigFont);
  line-height : 90%;
  text-transform : uppercase;
  color : var(--green);
  text-align: right;
  right:-12px;
  bottom:-26px;
  position: absolute;
  margin:0;
  background-color: var(--background);
  padding:24px 0 0 24px;
  max-width:90%;
}

#design{
  color:var(--yellow);
}

#about-content{
  font-size : 36px;
  color : var(--blue);
  max-width:100%;
  margin-left:50px;
  margin-right:50px;
  margin-top:50px;
  margin-bottom:calc((var(--bigFont) * 2) + 16px + 50px);
}

#about-content .specialty{
  font-weight: bold;
  color:var(--green)
}


.ant-layout-footer{
  margin-top:0px;
  text-align: center;
  margin-bottom:24px;
}

#portfolio-drawer .ant-drawer-content{
  background:var(--background);
}

#portfolio-drawer .ant-drawer-close {
  text-transform: uppercase;
  left:0;
  right:auto;
  padding-top:37.5px;
  padding-bottom:37.5px;
}

#portfolio-drawer .ant-drawer-header{
  border: none;
}


#portfolio-drawer .logo{
  background-image: url('img/logo-2.svg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  width:100%;
  height:60px;
  display: block;
}

.ant-drawer-body{
  padding:0;
  font-size: 18px;
  line-height: auto;
}

.portfolio{
  overflow: hidden;
}

#portfolio-drawer .portfolio .white-background:last-child{
  padding-bottom:50px;
}


.portfolio h1{
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 18px;
  line-height: 36px;
  text-align: center;
  margin-top:25px;
}
.portfolio .date-container{
  text-align: center;
  margin-bottom:24px;
}
.portfolio .date{
  font-size: 24px;
  line-height: 14px;
  margin-bottom:24px;
}

#portfolio-drawer .portfolio .ant-tag{
  vertical-align: text-bottom;
  font-weight: 600;
}

.portfolio h4{
  font-weight: bold;
  text-transform:uppercase;
  color:var(--pink);
  margin-bottom: 0;
}

.portfolio .hero-image{
  margin-top: 24px;
}

.portfolio .jump-button{
  margin-top:0px;
  color:var(--pink);
}

.portfolio h2{
  margin-bottom:0px;
  color:var(--green);
  font-size: var(--bigFont);
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  margin-top:50px;
  line-height: var(--bigFont);
}

.portfolio .brief{
  position: relative;
  border:11px solid var(--green);

  display: flex;
  justify-content: left;
  align-items: center;
  margin-top:50px;
  margin-bottom:50px;
}
.portfolio .brief-title{
  font-weight : bold;
  font-size : var(--bigFont);
  line-height : 90%;
  text-transform : uppercase;
  color : var(--green);
  text-align: right;
  right:-12px;
  bottom:-26px;
  position: absolute;
  margin:0;
  background-color: var(--background);
  padding:24px 0 0 24px;
}

.portfolio .brief-content{
  padding:100px;
  column-count: 2;
  column-gap: 50px;
}

.portfolio .brief-content.one-col{
  column-count: 1;
  width:60%
}

.portfolio .white-background{
  background:white;
  overflow: auto;
}

.portfolio .blue-background{
  background:var(--blue);
  color:var(--grey);
  overflow: auto;
}

.portfolio .blue-background h1{
  color:white;
}


.portfolio .small-p{
  max-width:600px;
}

.portfolio .quote{
  font-size: 36px;
  line-height: normal;
  position: relative;
  display: inline;
  width:50%;
  background-color: rgba(252, 199, 46, 0.2);
  z-index: 3;
}

.portfolio .quote-container{
  position: relative;
  display: block;
  max-width:500px;
  float: right;
  margin:50px;
  clear:both;
}

.portfolio .quote-wrapper::before,
.portfolio .quote-wrapper::after{
  color:var(--yellow);
  position: absolute;
  content: '“';
  font-size: 100px;
  line-height: 0;
  font-weight: bold;
}
.portfolio .quote-wrapper::before{
  left:0px;
  top:0px;

}
.portfolio .quote-wrapper::after{
  content: '”';
  bottom:-50px;
  text-align: right;
}

.portfolio ul {
  list-style: none; /* Remove default bullets */
}

.portfolio ul li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: var(--green); /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.portfolio figcaption{
  font-style: italic;
  text-align: center;
  max-width: 800px;
  margin:0 auto;
}

.portfolio .blue-background figcaption{
  color:var(--grey);
}

.portfolio .side-image{
  position: relative;
  display: block;
  max-width:500px;
  float: right;
  margin:50px;
  clear:both;
}

.ant-image-preview-operations .anticon-rotate-right,
.ant-image-preview-operations .anticon-rotate-left{
  display: none;
}

.portfolio .designs h2,
.portfolio .designs{
  text-align: center;
}


.browser-preview,
.mobile-browser-preview{
  margin-top:50px;
  margin-bottom:50px;
}



.browser-preview-container{
  display: flex;
  align-items: center;

}

.browser-preview .previous-button,
.browser-preview .next-button{
  opacity: 0;
  height:100%;
  padding:100px 10px 100px 10px;
  font-size:32px;
}
.browser-preview .previous-button.active,
.browser-preview .next-button.active{
  opacity: 1;
  cursor: pointer;
}
.browser-preview .previous-button{
  margin-right:24px;
}
.browser-preview .next-button{
  margin-left:24px;
}

.mobile-browser-preview .images{
  display: block;
}

.mobile-browser-preview .image{
  display: inline-block;
  margin-left: 12px;
  margin-right: 12px;
  margin-top:24px;
}

.mobile-browser-preview .caption{
  margin-bottom:24px;
}


.browser-preview .device,
.mobile-browser-preview .image img{
  height: 100%;
  border: 3px solid var(--green);
  padding:24px;
  border-radius: 5px;
  position: relative;
  margin: 0 auto;
}

.mobile-browser-preview .image img{
  max-height:calc(100vh - 250px);
  width: auto;
}


@keyframes blink {
  0%{
    opacity: 0;
  }
  20%{
    opacity: 1;
  }

  80%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

.browser-preview .device.scrollable::after{
  content:"↑ Scroll Me ↓";
  position: absolute;
  bottom:0;
  left:0;
  right:0;
  text-align: center;
  color:var(--green);
  font-size: 10px;
  font-weight: bold;
  line-height:24px;
  animation: blink 5s ease-in-out infinite;
}
.browser-preview .images-mask{
  width:100%;
  max-height:auto;
  display: flex;
  position: relative;
  overflow-x: hidden;

  height:auto;
  max-height:calc(100vh - 250px);
  width:auto;
}

.browser-preview .scrollable .images-mask{
  overflow: auto;
  height: 100%;
  max-height:calc(100vh - 250px);
}

.browser-preview .images{
  text-align: center;
  display: block;
  position: relative;
  height:100%;
  margin:0 auto;
}

.browser-preview .images .ant-image{
  display: block;
}
.browser-preview .scrollable .images .ant-image{
  height:100%;
}

.browser-preview .images img{
  display: block;
  max-width: 100%;
  max-height: 100%;
  width:auto;

  height:auto;
  max-height:calc(100vh - 250px);
  width:auto;
}

.browser-preview .scrollable .images img{
  max-height: none;
  width:100%;
}

.browser-preview .images .image{
  width:100%;
  display: none;
}

.browser-preview .images .image.active{
  display: block;
}


.browser-preview .dots{
  display: block;
  text-align:center;
  margin:0 auto;
  margin-top:12px;
}


.browser-preview .dot{
  background-color:var(--green);
  transition: background-color 0.35s ease-in-out;
  width:10px;
  height:10px;
  border-radius: 50%;
  display: inline-block;
  margin-left:5px;
  cursor: pointer;
}
.browser-preview .dot:first-child{
  margin-left: 0;
}

.browser-preview .dot.active{
  background-color:white;
  cursor:default;
}


.browser-preview .captions{
  width:calc(90vw - 100px);
  max-width:800px;
  display: block;
  text-align:center;
  margin:0 auto;
  margin-top:12px;
}


.browser-preview .caption{
  display: none;
  font-style: italic;
}

.browser-preview .caption.active{
  display: block;
}

.browser-preview .images .ant-image{
  display: block;
}

.portfolio section{
  clear: both;
}

#info{
  padding-top:50px;
  padding-bottom:50px;
  color:var(--grey);
}

#info h2{

  margin-top:0;
  margin-bottom:24px;
}

#info h3{
  color:var(--green);
}

#info-col-1{
  background-color:var(--blue);
  padding:50px;
}

#info-col-2{
  background-color:var(--green);
  background-image:url('img/christian_reich.jpg');
  background-repeat: no-repeat;
  background-position: 50% 22%;
  background-size: cover;
  padding:50px;
  min-height:300px;
}

#contact{
  background-color: var(--pink);
}
#contact .container{
  padding-top:50px;
  padding-bottom:50px;
}

#contact h2{
  color:var(--yellow);
}

.portfolio video{
  border: 3px solid var(--green);
  padding: 24px;
  border-radius: 5px;
  position: relative;
  margin: 0 auto;
  max-height:calc(100vh - 100px);
  max-width:100%;
}

.portfolio a{
  text-decoration: underline;
}


@media only screen and (max-width : 768px)  {
  :root {
    --bigFont: 50px;
    --mediumFont:36px;
  }

  #about-content{
    font-size : 24px;
  }

  .portfolio .quote{
    font-size: 24px;
  }

  .portfolio .brief-content {
      padding: 50px;
      column-count: 1;
  }

  .portfolio .brief-title{
    top:-12px;
    bottom:auto;
    left:-12px;
    right:auto;
    padding:0 24px 24px 0;
  }

  .portfolio .quote-container{
    max-width:100%;
    float: none;
  }

  .portfolio .side-image{
    max-width:100%;
    float: none;
  }
  #hero-text #visionary{
    display: block;
    margin:0 auto;
  }
}
